<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="5" class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mt-2">

            <router-link class="btn btn-primary" 
              :to="{ name: 'form-states-cities' }">
              Add New 
            </router-link>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="3">
            State
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input @keyup="checkString($event)" class="d-inline-block mr-1" placeholder="State..."
                v-model="seletedState"  />
            </div>
          </b-col>
          <b-col cols="12" md="3">
            City
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input @keyup="checkString($event)" class="d-inline-block mr-1" placeholder="City..."
                v-model="seletedCity"  />
            </div>
          </b-col>
          <b-col cols="12" md="1">
            <div class="d-flex justify-content-end">
              <button class="btn btn-primary mt-2" @click="masterSearch()">
                Filter
              </button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table ref="refUserListTable" class="position-relative" responsive primary-key="id" :items="listItems"
        :fields="fields" empty-text="No matching records found" :per-page="0" show-empty id="my-table"
        :current-page="currentPage">
        <template #cell(actions)="{ item }">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
            transition="scale-transition"
            :offset-y="true"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <template style="padding: 0" v-slot:activator="{ on, attrs }">
              <b-btn color="primary" v-bind="attrs" v-on="on" icon ripple>
              </b-btn>
            </template>
            <b-list-group
              style="padding: 2px; margin-bottom: 2px"
              dense
              rounded
            >
              <router-link
                class="urlPagina"
                :to="{
                  name: 'form-states-cities',
                  params: {
                    id: item.id,
                    item: item,
                  },
                }"
              >
                <b-list-group-item
                  style="padding: 0"
                  class="urlPagina"
                  :ripple="false"
                >
                  <b-list-group-item
                    class="font-weight-bold"
                    style="border: none; padding: 5px"
                  >
                    <feather-icon icon="FileTextIcon" />
                    Details
                  </b-list-group-item>
                </b-list-group-item>
              </router-link>
            </b-list-group>
            <b-list-group
              style="padding: 2px; margin-bottom: 2px"
              dense
              rounded
            >
              <b-list-group-item
                style="padding: 0"
                class="urlPagina"
                :ripple="false"
                v-if="$hasPermission('delete-records')"
                @click="deleteStateCity(item.id)"
              >
                <b-list-group-item
                  class="font-weight-bold"
                  style="border: none; padding: 5px"
                >
                  <feather-icon icon="TrashIcon" />
                  Delete
                </b-list-group-item>
              </b-list-group-item>
            </b-list-group>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ pagination.from }} to {{ pagination.to }} of
              {{ totalItems }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <div class="pagination_block">
              <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-center">
                  <li class="page-item" :class="{ disabled: pagination.prev_page_url == null }">
                    <button type="button" class="page-link" @click="getItems(pagination.first_page_url)">
                      &laquo;
                    </button>
                  </li>
                  <li class="page-item" :class="{ disabled: pagination.prev_page_url == null }">
                    <button type="button" class="page-link" @click="getItems(pagination.prev_page_url)">
                      Previous
                    </button>
                  </li>
                  <li class="page-item" v-if="currentPage - 2 > 0">
                    <button type="button" class="page-link" @click="
                      getItems(
                        pagination.path + '?page=' + (currentPage - 2)
                      )
                      ">
                      {{ currentPage - 2 }}
                    </button>
                  </li>
                  <li class="page-item" v-if="pagination.prev_page_url">
                    <button type="button" class="page-link" @click="getItems(pagination.prev_page_url)">
                      {{ currentPage - 1 }}
                    </button>
                  </li>
                  <li class="page-item">
                    <button type="button" class="page-link" :class="{
                      current: pagination.current_page == currentPage,
                    }">
                      {{ currentPage }}
                    </button>
                  </li>
                  <li class="page-item" v-if="pagination.next_page_url">
                    <button type="button" class="page-link" @click="getItems(pagination.next_page_url)">
                      {{ currentPage + 1 }}
                    </button>
                  </li>
                  <li class="page-item" v-if="currentPage + 2 <= lastPage">
                    <button type="button" class="page-link" @click="
                      getItems(
                        pagination.path + '?page=' + (currentPage + 2)
                      )
                      ">
                      {{ currentPage + 2 }}
                    </button>
                  </li>
                  <li class="page-item" :class="{ disabled: pagination.next_page_url == null }">
                    <button type="button" class="page-link" @click="getItems(pagination.next_page_url)">
                      Next
                    </button>
                  </li>
                  <li class="page-item" :class="{ disabled: pagination.next_page_url == null }">
                    <button type="button" class="page-link" @click="getItems(pagination.last_page_url)">
                      &raquo;
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import moment from "moment";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormTextarea,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BListGroup,
  BListGroupItem,
  BFormSelect,
  BTableSimple,
  BThead,
  BTr,
  BTd,
  BTh,
  BTbody,
  BTfoot,
  BFormGroup,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { getCentralTime, getMessageError } from "@core/utils/utils";
import { convertToMMDDYYYY, convertToHHMM, convertToHHMM2, addTimes, timeToSecs, secsToTime } from "@core/utils/dates";
import UserListAddNew from "@core/components/infoClients/UserListAddNew";
import { selectedRow } from "@core/utils/cssStyle";
export default {
  name: "ListReservationToCancel",
  components: {
    UserListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BListGroup,
    BListGroupItem,
    BFormSelect,
    BTableSimple,
    BThead,
    BTr,
    BTd,
    BTh,
    BTbody,
    BTfoot,
    BFormGroup,
    flatPickr,
    vSelect,
    BFormInput,
    moment,
  },
  data() {
    return {
      listItems:[],
      perPage: 5,
      currentPage: 1,
      fields: [   
      { label: "State", key: "state_full" },    
        "city",
        "actions"
      ],
      totalItems: 0,
      pagination: {},
      lastPage: 0,

      seletedState:null,
      seletedCity:null,
    };
  },
  methods: {
    masterSearch() {
        this.getItems("");
    },
    getItems(url) {
      this.getItems(url);
    },
    async getItems(url) {
      console.log("xxxxxxxxxxx")
      this.$swal({
        title: "Please, wait loading data...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });

      let data = this.getValuesParameters();

      if (url.length === 0) {
        url = `states_cities/filter_cities_states`;
      }

      await this.$http
        .post(url, data)
        .then((response) => {
          this.listItems = response.data.data.data;
          this.totalItems = response.data.data.total;
          this.currentPage = response.data.data.current_page;
          this.lastPage = response.data.data.last_page;
          this.pagination = response.data.data;
          this.perPage = response.data.data.per_page;
          this.$swal.close();
        })
        .catch((res) => {
          this.$swal({
            title: getMessageError(res),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    getValuesParameters() {
      let data = {};
      let state=this.seletedState;
      let city=this.seletedCity;

      data = {
        state: state,
        city: city,
      };

      return data;
    },
    deleteStateCity(id) {
      this.$swal({
        title: "Do you want delete this record?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        buttonsStyling: true,
      }).then((result) => {
        if (result.value === true) {
          this.$swal({
            title: "Please, wait...",
            didOpen: () => {
              this.$swal.showLoading();
            },
          });
          this.$http
            .post(`/states_cities/delete_city_state/${id}`)
            .then((res) => {
              this.$swal({
                title: res.data.data,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  this.getItems("");
                }
              });
            })
            .catch((error) => {
              this.$swal({
                title: getMessageError(error),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    async loadData() {
      await this.getItems("");
    },
    checkString(e) {
      if (e.keyCode == 13) {
        this.getItems("");
      }
    },
  },
  mounted() {
    this.loadData();
  },

};
</script>
    
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.urlPagina {
  text-decoration: none;
  color: #7367f0;
}

.urlPagina:hover {
  background: linear-gradient(118deg,
      #7367f0,
      rgba(115, 103, 240, 0.7)) !important;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
}

.list-group-item:hover {
  background: linear-gradient(118deg,
      #7367f0,
      rgba(115, 103, 240, 0.7)) !important;
  color: #fff !important;
  cursor: pointer;
}

.urlPagina::before {
  background-color: currentColor !important;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.box {
  box-shadow: 0px 14px 20px 0px rgba(143, 143, 143, 0.2) !important;
}

.my-class {
  background: #7367f0;
}

.green_timer {
  background: green;
  color: white;
  padding: 3px;
  border-radius: 8px;
}

.current {
  background: #7367f0;
  color: white;
}

.table-responsive {
  overflow-x: clip !important;
}

.headerth {
  cursor: pointer;
}

.highlighted {
  background: red;
}

tr {
  cursor: pointer;
  padding: 0;
}

th {
  background: #4b4b4b !important;
  color: white !important;
  text-transform: uppercase;
}

td {
  padding: 0;
}

.table td,
.table th {
  padding: 3px;
  font-size: x-small;
  min-width: 40px;
  border: solid 1px;
  border-color: #303033;
  background: #fdfdfd;
  color: #303033;
}

.show_space {
  padding-bottom: 250px;
}
</style>
    
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
  